
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

html {
  line-height: 1; }

ol,
ul {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

caption,
th,
td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

q,
blockquote {
  quotes: none; }

q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
  content: none; }

a img {
  border: none; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

a {
  text-decoration: none;
  color: black;
  -webkit-transition: 0.3s color linear;
  -o-transition: 0.3s color linear;
  transition: 0.3s color linear; }

b,
strong {
  font-family: "Roboto Bold", sans-serif; }

body {
  font-family: "Roboto", sans-serif;
  max-width: 1920px;
  margin: 0 auto;
  color: #31425a; }

.container {
  padding: 0 50px;
  width: 100%;
  margin: 0 auto; }
  @media (min-width: 1366px) {
    .container {
      width: 1349px; } }
  @media (max-width: 767px) {
    .container {
      padding: 0 25px; } }

main.home, main.contact {
  margin-top: 105px; }
  @media (min-width: 768px) {
    main.home, main.contact {
      margin-top: 135px; } }

@font-face {
  font-family: "Roboto Medium";
  src: url("../fonts/subset-Roboto-Medium.eot");
  src: url("../fonts/subset-Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-Roboto-Medium.woff2") format("woff2"), url("../fonts/subset-Roboto-Medium.woff") format("woff"), url("../fonts/subset-Roboto-Medium.ttf") format("truetype"), url("../fonts/subset-Roboto-Medium.svg#Roboto-Medium") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Roboto Light";
  src: url("../fonts/subset-Roboto-Light.eot");
  src: url("../fonts/subset-Roboto-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-Roboto-Light.woff2") format("woff2"), url("../fonts/subset-Roboto-Light.woff") format("woff"), url("../fonts/subset-Roboto-Light.ttf") format("truetype"), url("../fonts/subset-Roboto-Light.svg#Roboto-Light") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Roboto Bold";
  src: url("../fonts/subset-Roboto-Bold.eot");
  src: url("../fonts/subset-Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-Roboto-Bold.woff2") format("woff2"), url("../fonts/subset-Roboto-Bold.woff") format("woff"), url("../fonts/subset-Roboto-Bold.ttf") format("truetype"), url("../fonts/subset-Roboto-Bold.svg#Roboto-Bold") format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: "Roboto Black";
  src: url("../fonts/subset-Roboto-Black.eot");
  src: url("../fonts/subset-Roboto-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-Roboto-Black.woff2") format("woff2"), url("../fonts/subset-Roboto-Black.woff") format("woff"), url("../fonts/subset-Roboto-Black.ttf") format("truetype"), url("../fonts/subset-Roboto-Black.svg#Roboto-Black") format("svg");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: "Roboto";
  src: url("../fonts/subset-Roboto-Regular.eot");
  src: url("../fonts/subset-Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-Roboto-Regular.woff2") format("woff2"), url("../fonts/subset-Roboto-Regular.woff") format("woff"), url("../fonts/subset-Roboto-Regular.ttf") format("truetype"), url("../fonts/subset-Roboto-Regular.svg#Roboto-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

h2 {
  font-size: 52px; }
  h2.bolder {
    font-family: "Roboto Black", sans-serif; }

h2,
h3,
h5,
h5,
h6 {
  font-family: "Roboto Bold", sans-serif; }

h3 {
  font-size: 40px; }

h4 {
  font-size: 26px;
  line-height: 30px;
  font-family: "Roboto Medium", sans-serif; }

h5 {
  font-size: 18px; }

h6 {
  font-size: 16px; }

@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?gw2bl2");
  src: url("../fonts/icomoon.eot?gw2bl2#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?gw2bl2") format("truetype"), url("../fonts/icomoon.woff?gw2bl2") format("woff"), url("../fonts/icomoon.svg?gw2bl2#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease; }

.icon-linkedin::before {
  content: "\e929"; }

.icon-cat3::before{
  content: "\e92a"; }

.icon-cat1:before {
  content: "\e92b"; }

.icon-phone:before {
  content: "\e92d"; }

.icon-cat2:before {
  content: "\e92e"; }

.icon-cat0:before {
  content: "\e92f"; }

.icon-fb:before {
  content: "\e930"; }

.icon-left:before {
  content: "\e91c"; }

.icon-right:before {
  content: "\e900"; }

.main-header {
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 3;
  max-width: 1920px;
  background-color: white; }
  .main-header.moving > .container {
    padding: 15px 10px; }
    .main-header.moving > .container .logo {
      -webkit-transform: scale(0.6);
          -ms-transform: scale(0.6);
              transform: scale(0.6); }
      @media (max-width: 767px) {
        .main-header.moving > .container .logo {
          -webkit-transform: scale(0.6);
              -ms-transform: scale(0.6);
                  transform: scale(0.6); } }
  .main-header > .container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-align: center;
        align-items: center;
    padding: 30px 10px;
    -webkit-transition: padding 0.3s ease;
    -o-transition: padding 0.3s ease;
    transition: padding 0.3s ease; }
    .main-header > .container .logo {
      width: 254px;
      -webkit-transition: -webkit-transform 0.3s ease;
      transition: -webkit-transform 0.3s ease;
      -o-transition: transform 0.3s ease;
      transition: transform 0.3s ease;
      transition: transform 0.3s ease, -webkit-transform 0.3s ease;
      display: block; }
      @media (max-width: 767px) {
        .main-header > .container .logo {
          max-width: 154px;
          width: 41vw; } }
    .main-header > .container .main-menu a {
      margin-right: 30px;
      font-size: 14px;
      font-family: "Roboto Medium", sans-serif;
      text-transform: uppercase;
      cursor: pointer; }
      @media (max-width: 767px) {
        .main-header > .container .main-menu a {
          margin-right: 20px; } }
      .main-header > .container .main-menu a:hover {
        color: #ffc321; }
      @media (max-width: 1023px) {
        .main-header > .container .main-menu a.scroll {
          display: none; } }
      .main-header > .container .main-menu a.scroll.active-link {
        color: #ffc321; }
      .main-header > .container .main-menu a.active {
        border-bottom: 1px solid #ffc321; }
      .main-header > .container .main-menu a.social {
        margin-right: 15px; }
      .main-header > .container .main-menu a.lang {
        margin-right: 80px;
        color: #ffc321; }
        @media (max-width: 1024px) {
          .main-header > .container .main-menu a.lang {
            margin-right: 40px; } }
        @media (max-width: 767px) {
          .main-header > .container .main-menu a.lang {
            margin-right: 0; } }
      @media (max-width: 767px) {
        .main-header > .container .main-menu a.social {
          display: none; } }

a.social {
  margin-right: 15px; }
  a.social [class^="icon-"] {
    background-color: #afb8bc;
    border-radius: 50%;
    color: white;
    font-size: 20px;
    padding: 5px;
    -webkit-transition: background-color 0.3s ease;
    -o-transition: background-color 0.3s ease;
    transition: background-color 0.3s ease; }
  a.social:hover [class^="icon-"] {
    background-color: #1cbbb4; }

.slider .slide {
  position: relative; }
  .slider .slide:after {
    content: "";
    background-color: rgba(6, 40, 66, 0.2);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
    @media (max-width: 767px) {
      .slider .slide:after {
        background-color: rgba(6, 40, 66, 0.4); } }
  .slider .slide img {
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    display: block; }
    @media (min-width: 1024px) {
      .slider .slide img {
        min-height: 560px; } }
    @media (max-width: 1024px) and (min-width: 1024px) {
      .slider .slide img {
        height: 560px; } }
    @media (max-width: 1023px) {
      .slider .slide img {
        height: 400px; } }
    @media (max-width: 767px) {
      .slider .slide img {
        height: 110vh; } }
  .slider .slide.show .caption > * {
    opacity: 1;
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0); }
  .slider .slide .caption {
    position: absolute;
    right: 100px;
    top: 100px;
    z-index: 1; }
    .slider .slide .caption > * {
      opacity: 0;
      -webkit-transform: translateY(100%);
          -ms-transform: translateY(100%);
              transform: translateY(100%);
      -webkit-transition: opacity 1s ease, -webkit-transform 1s ease;
      transition: opacity 1s ease, -webkit-transform 1s ease;
      -o-transition: opacity 1s ease, transform 1s ease;
      transition: opacity 1s ease, transform 1s ease;
      transition: opacity 1s ease, transform 1s ease, -webkit-transform 1s ease; }
    @media (max-width: 1024px) {
      .slider .slide .caption {
        top: 50px; } }
    @media (max-width: 1023px) {
      .slider .slide .caption {
        top: 100px;
        right: auto;
        left: 20px; } }
    @media (max-width: 767px) {
      .slider .slide .caption {
        top: 75px; } }
    @media (min-width: 1024px) {
      .slider .slide .caption {
        text-align: right;
        max-width: 35%; } }
    .slider .slide .caption h2 {
      line-height: 55px;
      margin-bottom: 40px;
      letter-spacing: 3px;
      -webkit-transition-delay: 0.6s;
           -o-transition-delay: 0.6s;
              transition-delay: 0.6s; }
      @media (max-width: 767px) {
        .slider .slide .caption h2 {
          font-size: 30px;
          line-height: 40px; } }
    .slider .slide .caption h4 {
      margin-bottom: 40px;
      -webkit-transition-delay: 0.8s;
           -o-transition-delay: 0.8s;
              transition-delay: 0.8s; }
      @media (max-width: 1023px) {
        .slider .slide .caption h4 {
          display: none; } }
    .slider .slide .caption p {
      font-size: 14px;
      -webkit-transition-delay: 1s;
           -o-transition-delay: 1s;
              transition-delay: 1s; }
      @media (max-width: 1023px) {
        .slider .slide .caption p {
          display: none; } }
    .slider .slide .caption > * {
      color: white; }

.pagination {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-top: -80px; }
  @media (min-width: 1025px) {
    .pagination {
      padding: 0 100px; } }
  @media (max-width: 1023px) and (min-width: 768px) {
    .pagination {
      margin-bottom: 80px; } }
  @media (max-width: 767px) {
    .pagination {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      position: absolute;
      bottom: -350px;
      width: 250px;
      left: 0;
      right: 0; } }
  .pagination > div {
    width: 25%;
    font-family: "Roboto Bold", sans-serif;
    color: #50504f;
    font-size: 18px;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    text-align: center;
    z-index: 2; }
    @media (max-width: 767px) {
      .pagination > div:first-of-type {
        width: 100%;
        margin-bottom: -27px; }
      .pagination > div:last-of-type {
        width: 100%;
        margin-top: -27px; } }
    .pagination > div:first-of-type [class^="icon-"] {
      font-size: 50px; }
      @media (min-width: 768px) {
        .pagination > div:first-of-type [class^="icon-"] {
          font-size: 63px; } }
    .pagination > div h5 {
      margin-top: 80px;
      z-index: 1; }
      @media (max-width: 1023px) {
        .pagination > div h5 {
          display: none; } }
    .pagination > div p {
      font-size: 13px;
      line-height: 24px;
      max-width: 85%;
      margin-top: 10px;
      opacity: 0;
      -webkit-transition: opacity 0.5s ease-in-out;
      -o-transition: opacity 0.5s ease-in-out;
      transition: opacity 0.5s ease-in-out; }
      @media (max-width: 1023px) {
        .pagination > div p {
          display: none; } }
    @media (min-width: 768px) {
      .pagination > div:after {
        content: "";
        height: 155px;
        width: 155px;
        background-color: #e8e8e8;
        position: absolute;
        top: 0;
        -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
                transform: rotate(-45deg);
        -webkit-transition: -webkit-transform 0.5s ease-in-out;
        transition: -webkit-transform 0.5s ease-in-out;
        -o-transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out; } }
    .pagination > div [class^="icon-"] {
      cursor: pointer;
      -ms-flex-order: -1;
          order: -1;
      color: #ffc321;
      font-size: 70px;
      width: 100px;
      height: 100px;
      -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
              transform: rotate(45deg);
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center;
      background-color: #fbfafa;
      z-index: 1;
      position: relative;
      -webkit-transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
      -o-transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
      transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out; }
      @media (min-width: 768px) {
        .pagination > div [class^="icon-"] {
          height: 155px;
          width: 155px;
          font-size: 93px; } }
      .pagination > div [class^="icon-"]:before {
        -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
                transform: rotate(-45deg);
        display: block; }
    .pagination > div.active:after, .pagination > div:hover:after {
      -webkit-transform: translateY(10px) rotate(-45deg);
          -ms-transform: translateY(10px) rotate(-45deg);
              transform: translateY(10px) rotate(-45deg); }
    .pagination > div.active [class^="icon-"], .pagination > div:hover [class^="icon-"] {
      color: white;
      background-color: #ffc321; }
    .pagination > div.active p, .pagination > div:hover p {
      opacity: 1; }

.scrollspy h2,
.scrollspy h3,
.scrollspy h4,
.scrollspy .counters-list,
.scrollspy .carousel,
.scrollspy p,
.scrollspy form,
.scrollspy .icon-left,
.scrollspy .icon-right,
.scrollspy .social {
  opacity: 0;
  -webkit-transform: translateY(100%);
      -ms-transform: translateY(100%);
          transform: translateY(100%);
  -webkit-transition: opacity 1s ease, -webkit-transform 1s ease;
  transition: opacity 1s ease, -webkit-transform 1s ease;
  -o-transition: opacity 1s ease, transform 1s ease;
  transition: opacity 1s ease, transform 1s ease;
  transition: opacity 1s ease, transform 1s ease, -webkit-transform 1s ease; }

.scrollspy .btn {
  opacity: 0;
  -webkit-transform: translateY(100%);
      -ms-transform: translateY(100%);
          transform: translateY(100%); }

.scrollspy.animated h2,
.scrollspy.animated h3,
.scrollspy.animated h4,
.scrollspy.animated .counters-list,
.scrollspy.animated .carousel,
.scrollspy.animated p,
.scrollspy.animated form,
.scrollspy.animated .icon-left,
.scrollspy.animated .icon-right,
.scrollspy.animated .social,
.scrollspy.animated .btn {
  opacity: 1;
  -webkit-transform: translateY(0%);
      -ms-transform: translateY(0%);
          transform: translateY(0%); }

#process {
  background-color: #f7f4f4;
  color: #31425a;
  padding: 60px 0 80px; }
  #process.animated .circle {
    opacity: 1;
    -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
            transform: translateX(0); }
    #process.animated .circle:after {
      opacity: 1;
      -webkit-transform: scaleX(1);
          -ms-transform: scaleX(1);
              transform: scaleX(1); }
    #process.animated .circle:nth-of-type(even):after {
      -webkit-transform: rotate(180deg) scaleX(-1);
          -ms-transform: rotate(180deg) scaleX(-1);
              transform: rotate(180deg) scaleX(-1); }
  #process h2,
  #process h4 {
    text-align: center; }
  #process h4 {
    font-family: "Roboto Light", sans-serif;
    margin-bottom: 40px; }
    @media (max-width: 767px) {
      #process h4 {
        margin: 40px 0; } }
    #process h4.bolder {
      font-family: "Roboto Bold", sans-serif; }
  #process .circle-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-align: start;
        align-items: flex-start;
    margin: 40px 0 60px; }
    @media (max-width: 1024px) {
      #process .circle-list {
        -ms-flex-pack: start;
            justify-content: flex-start;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; } }
    @media (max-width: 1023px) {
      #process .circle-list {
        -ms-flex-pack: center;
            justify-content: center; } }
    @media (max-width: 767px) {
      #process .circle-list {
        -ms-flex-pack: start;
            justify-content: flex-start;
        max-width: 320px;
        margin: 0 auto; } }
  #process .circle {
    position: relative;
    width: 170px;
    height: 170px;
    min-height: 170px;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    text-align: center;
    color: white;
    -webkit-box-shadow: 0px 0px #e1dede;
            box-shadow: 0px 0px #e1dede;
    -webkit-transition: border-radius 0.6s ease, color 0.3s ease, opacity 1s ease, -webkit-box-shadow 0.6s ease,
 -webkit-transform 1s ease;
    transition: border-radius 0.6s ease, color 0.3s ease, opacity 1s ease, -webkit-box-shadow 0.6s ease,
 -webkit-transform 1s ease;
    -o-transition: border-radius 0.6s ease, color 0.3s ease, box-shadow 0.6s ease,
 opacity 1s ease, transform 1s ease;
    transition: border-radius 0.6s ease, color 0.3s ease, box-shadow 0.6s ease,
 opacity 1s ease, transform 1s ease;
    transition: border-radius 0.6s ease, color 0.3s ease, box-shadow 0.6s ease,
 opacity 1s ease, transform 1s ease, -webkit-box-shadow 0.6s ease,
 -webkit-transform 1s ease;
    opacity: 0;
    -webkit-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
            transform: translateX(-100%); }
    #process .circle h3 {
      font-family: "Roboto Light", sans-serif;
      margin: 30px 0 20px; }
    #process .circle h6 {
      letter-spacing: 1px; }
    #process .circle p {
      display: none;
      font-size: 14px;
      font-family: "Roboto Bold", sans-serif;
      line-height: 18px; }
    #process .circle:hover {
      border-radius: 0;
      -webkit-box-shadow: 0px 10px #e1dede;
              box-shadow: 0px 10px #e1dede;
      height: 100%; }
      #process .circle:hover p {
        height: 150px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
            align-items: center; }
      #process .circle:hover h6,
      #process .circle:hover h3 {
        display: none; }
    #process .circle:after {
      content: "";
      position: absolute;
      top: 0;
      right: -101px;
      background-image: url("../images/arrow.svg");
      height: 68px;
      width: 91px;
      background-size: cover;
      display: block;
      opacity: 0;
      -webkit-transform: scaleX(0);
          -ms-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transition: opacity 1s ease, -webkit-transform 1s ease;
      transition: opacity 1s ease, -webkit-transform 1s ease;
      -o-transition: opacity 1s ease, transform 1s ease;
      transition: opacity 1s ease, transform 1s ease;
      transition: opacity 1s ease, transform 1s ease, -webkit-transform 1s ease; }
      @media (max-width: 767px) {
        #process .circle:after {
          top: auto;
          bottom: 25px; } }
    @media (max-width: 767px) {
      #process .circle:nth-of-type(odd):not(:first-of-type):after {
        left: 50px;
        bottom: -100px;
        right: auto;
        -webkit-transform: rotate(-90deg) scaleX(-1);
            -ms-transform: rotate(-90deg) scaleX(-1);
                transform: rotate(-90deg) scaleX(-1); } }
    @media (max-width: 359px) {
      #process .circle:nth-of-type(odd):not(:first-of-type):after {
        left: 0; } }
    #process .circle:nth-of-type(even) {
      margin-top: 80px; }
      #process .circle:nth-of-type(even):after {
        -webkit-transform: rotate(180deg) scaleX(0);
            -ms-transform: rotate(180deg) scaleX(0);
                transform: rotate(180deg) scaleX(0);
        top: auto;
        bottom: 0; }
      @media (max-width: 1024px) {
        #process .circle:nth-of-type(even) {
          margin-bottom: 20px; } }
      @media (max-width: 767px) {
        #process .circle:nth-of-type(even) {
          margin-left: auto;
          margin-bottom: 0;
          margin-top: 0; }
          #process .circle:nth-of-type(even):after {
            right: 50px;
            top: auto;
            bottom: -100px;
            -webkit-transform: rotate(90deg) scaleX(1);
                -ms-transform: rotate(90deg) scaleX(1);
                    transform: rotate(90deg) scaleX(1); } }
      @media (max-width: 359px) {
        #process .circle:nth-of-type(even):after {
          right: 0; } }
    #process .circle:nth-of-type(1) {
      background-color: #b6cdcc; }
      #process .circle:nth-of-type(1):after {
        -webkit-transition-delay: 0.5s;
             -o-transition-delay: 0.5s;
                transition-delay: 0.5s; }
    #process .circle:nth-of-type(2) {
      background-color: #4cc4ba;
      -webkit-transition: border-radius 0.6s ease, color 0.3s ease, opacity 1s ease 0.25s, -webkit-box-shadow 0.6s ease,
 -webkit-transform 1s ease 0.25s;
      transition: border-radius 0.6s ease, color 0.3s ease, opacity 1s ease 0.25s, -webkit-box-shadow 0.6s ease,
 -webkit-transform 1s ease 0.25s;
      -o-transition: border-radius 0.6s ease, color 0.3s ease, box-shadow 0.6s ease,
 opacity 1s ease 0.25s, transform 1s ease 0.25s;
      transition: border-radius 0.6s ease, color 0.3s ease, box-shadow 0.6s ease,
 opacity 1s ease 0.25s, transform 1s ease 0.25s;
      transition: border-radius 0.6s ease, color 0.3s ease, box-shadow 0.6s ease,
 opacity 1s ease 0.25s, transform 1s ease 0.25s, -webkit-box-shadow 0.6s ease,
 -webkit-transform 1s ease 0.25s; }
      #process .circle:nth-of-type(2):after {
        -webkit-transition-delay: 0.5s;
             -o-transition-delay: 0.5s;
                transition-delay: 0.5s; }
    #process .circle:nth-of-type(3) {
      background-color: #0ab2bc;
      -webkit-transition: border-radius 0.6s ease, color 0.3s ease, opacity 1s ease 0.5s, -webkit-box-shadow 0.6s ease,
 -webkit-transform 1s ease 0.5s;
      transition: border-radius 0.6s ease, color 0.3s ease, opacity 1s ease 0.5s, -webkit-box-shadow 0.6s ease,
 -webkit-transform 1s ease 0.5s;
      -o-transition: border-radius 0.6s ease, color 0.3s ease, box-shadow 0.6s ease,
 opacity 1s ease 0.5s, transform 1s ease 0.5s;
      transition: border-radius 0.6s ease, color 0.3s ease, box-shadow 0.6s ease,
 opacity 1s ease 0.5s, transform 1s ease 0.5s;
      transition: border-radius 0.6s ease, color 0.3s ease, box-shadow 0.6s ease,
 opacity 1s ease 0.5s, transform 1s ease 0.5s, -webkit-box-shadow 0.6s ease,
 -webkit-transform 1s ease 0.5s; }
      #process .circle:nth-of-type(3):after {
        -webkit-transition-delay: 0.75s;
             -o-transition-delay: 0.75s;
                transition-delay: 0.75s; }
    #process .circle:nth-of-type(4) {
      background-color: #008ba5;
      -webkit-transition: border-radius 0.6s ease, color 0.3s ease, opacity 1s ease 0.75s, -webkit-box-shadow 0.6s ease,
 -webkit-transform 1s ease 0.75s;
      transition: border-radius 0.6s ease, color 0.3s ease, opacity 1s ease 0.75s, -webkit-box-shadow 0.6s ease,
 -webkit-transform 1s ease 0.75s;
      -o-transition: border-radius 0.6s ease, color 0.3s ease, box-shadow 0.6s ease,
 opacity 1s ease 0.75s, transform 1s ease 0.75s;
      transition: border-radius 0.6s ease, color 0.3s ease, box-shadow 0.6s ease,
 opacity 1s ease 0.75s, transform 1s ease 0.75s;
      transition: border-radius 0.6s ease, color 0.3s ease, box-shadow 0.6s ease,
 opacity 1s ease 0.75s, transform 1s ease 0.75s, -webkit-box-shadow 0.6s ease,
 -webkit-transform 1s ease 0.75s; }
      #process .circle:nth-of-type(4):after {
        -webkit-transition-delay: 1s;
             -o-transition-delay: 1s;
                transition-delay: 1s; }
    #process .circle:nth-of-type(5) {
      background-color: #31425a;
      -webkit-transition: border-radius 0.6s ease, color 0.3s ease, opacity 1s ease 1s, -webkit-box-shadow 0.6s ease,
 -webkit-transform 1s ease 1s;
      transition: border-radius 0.6s ease, color 0.3s ease, opacity 1s ease 1s, -webkit-box-shadow 0.6s ease,
 -webkit-transform 1s ease 1s;
      -o-transition: border-radius 0.6s ease, color 0.3s ease, box-shadow 0.6s ease,
 opacity 1s ease 1s, transform 1s ease 1s;
      transition: border-radius 0.6s ease, color 0.3s ease, box-shadow 0.6s ease,
 opacity 1s ease 1s, transform 1s ease 1s;
      transition: border-radius 0.6s ease, color 0.3s ease, box-shadow 0.6s ease,
 opacity 1s ease 1s, transform 1s ease 1s, -webkit-box-shadow 0.6s ease,
 -webkit-transform 1s ease 1s;
      color: white; }
      #process .circle:nth-of-type(5):after {
        -webkit-transition-delay: 1.25s;
             -o-transition-delay: 1.25s;
                transition-delay: 1.25s; }
    #process .circle:nth-of-type(6) {
      background-color: #86969f;
      -webkit-transition: border-radius 0.6s ease, color 0.3s ease, opacity 1s ease 1.25s, -webkit-box-shadow 0.6s ease,
 -webkit-transform 1s ease 1.25s;
      transition: border-radius 0.6s ease, color 0.3s ease, opacity 1s ease 1.25s, -webkit-box-shadow 0.6s ease,
 -webkit-transform 1s ease 1.25s;
      -o-transition: border-radius 0.6s ease, color 0.3s ease, box-shadow 0.6s ease,
 opacity 1s ease 1.25s, transform 1s ease 1.25s;
      transition: border-radius 0.6s ease, color 0.3s ease, box-shadow 0.6s ease,
 opacity 1s ease 1.25s, transform 1s ease 1.25s;
      transition: border-radius 0.6s ease, color 0.3s ease, box-shadow 0.6s ease,
 opacity 1s ease 1.25s, transform 1s ease 1.25s, -webkit-box-shadow 0.6s ease,
 -webkit-transform 1s ease 1.25s; }
      #process .circle:nth-of-type(6):after {
        -webkit-transition-delay: 1.5s;
             -o-transition-delay: 1.5s;
                transition-delay: 1.5s; }
    #process .circle:nth-of-type(7) {
      background-color: #b0b9bd;
      -webkit-transition: border-radius 0.6s ease, color 0.3s ease, opacity 1s ease 1.5s, -webkit-box-shadow 0.6s ease,
 -webkit-transform 1s ease 1.5s;
      transition: border-radius 0.6s ease, color 0.3s ease, opacity 1s ease 1.5s, -webkit-box-shadow 0.6s ease,
 -webkit-transform 1s ease 1.5s;
      -o-transition: border-radius 0.6s ease, color 0.3s ease, box-shadow 0.6s ease,
 opacity 1s ease 1.5s, transform 1s ease 1.5s;
      transition: border-radius 0.6s ease, color 0.3s ease, box-shadow 0.6s ease,
 opacity 1s ease 1.5s, transform 1s ease 1.5s;
      transition: border-radius 0.6s ease, color 0.3s ease, box-shadow 0.6s ease,
 opacity 1s ease 1.5s, transform 1s ease 1.5s, -webkit-box-shadow 0.6s ease,
 -webkit-transform 1s ease 1.5s; }
      #process .circle:nth-of-type(7):after {
        -webkit-transition-delay: 1.75s;
             -o-transition-delay: 1.75s;
                transition-delay: 1.75s; }
    #process .circle:last-of-type:after {
      display: none; }
    @media (max-width: 1023px) and (min-width: 768px) {
      #process .circle:nth-of-type(3n):after {
        display: none; } }

#counters {
  height: 430px;
  background-image: url("../images/parallax1.jpg");
  background-position: center;
  background-size: cover;
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex; }
  @media (min-width: 768px) {
    #counters {
      background-attachment: fixed; } }
  @supports (background-blend-mode: multiply) {
    #counters {
      background-color: #767069;
      background-blend-mode: multiply; } }
  @supports not (background-blend-mode: multiply) {
    #counters {
      position: relative;
      z-index: 1; }
      #counters:after {
        content: "";
        background-color: rgba(118, 112, 105, 0.54);
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1; } }
  @media (max-width: 767px) {
    #counters {
      height: 100%;
      padding: 20px; }
      #counters .container {
        padding: 0; } }
  #counters .counters-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between; }
    @media (max-width: 1023px) {
      #counters .counters-list {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; } }
    #counters .counters-list div {
      color: white;
      text-align: center; }
      @media (max-width: 1023px) {
        #counters .counters-list div {
          width: 50%;
          margin-bottom: 20px;
          -ms-flex-item-align: center;
              align-self: center; } }
      #counters .counters-list div p {
        text-transform: uppercase;
        font-family: "Roboto Bold", sans-serif;
        font-size: 14px;
        line-height: 1.3;
        margin-top: 10px; }
    #counters .counters-list span {
      font-size: 50px; }
      @media (max-width: 767px) {
        #counters .counters-list span {
          font-size: 40px; } }

#clients {
  padding: 100px 0 80px; }
  #clients h2 {
    text-align: center;
    color: #31425a;
    margin-bottom: 70px; }
  #clients .container {
    position: relative; }
  #clients .carousel > div {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
    #clients .carousel > div > div {
      text-align: center; }
    #clients .carousel > div img {
      -webkit-filter: grayscale(100%);
              filter: grayscale(100%);
      -webkit-transition: -webkit-filter 0.3s ease;
      transition: -webkit-filter 0.3s ease;
      -o-transition: filter 0.3s ease;
      transition: filter 0.3s ease;
      transition: filter 0.3s ease, -webkit-filter 0.3s ease; }
      #clients .carousel > div img:hover {
        -webkit-filter: grayscale(0%);
                filter: grayscale(0%); }
  #clients [class^="icon-"] {
    font-size: 40px;
    position: absolute;
    color: #31425a;
    cursor: pointer;
    bottom: 20%; }
  #clients .icon-left {
    left: 0; }
  #clients .icon-right {
    right: 0; }

.cta {
  background-color: #f2f2f2;
  padding: 70px 0; }
  .cta > div {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-align: center;
        align-items: center;
    color: #31425a; }
    @media (max-width: 1023px) {
      .cta > div {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -ms-flex-pack: center;
            justify-content: center; } }
    .cta > div .btn {
      padding: 40px 80px;
      text-transform: uppercase; }
      @media (max-width: 1023px) {
        .cta > div .btn {
          margin-top: 40px; } }
      @media (max-width: 767px) {
        .cta > div .btn {
          padding: 20px;
          font-size: 18px; } }
      @media (max-width: 359px) {
        .cta > div .btn {
          font-size: 14px; } }

a.btn,
button.btn {
  font-size: 20px;
  font-family: "Roboto Light", sans-serif;
  border: none;
  text-align: center;
  background-color: #1cbbb4;
  padding: 20px 40px;
  color: white;
  letter-spacing: 3px;
  -webkit-transition: opacity 1s ease, -webkit-box-shadow 0.3s ease, -webkit-transform 1s ease;
  transition: opacity 1s ease, -webkit-box-shadow 0.3s ease, -webkit-transform 1s ease;
  -o-transition: box-shadow 0.3s ease, opacity 1s ease, transform 1s ease;
  transition: box-shadow 0.3s ease, opacity 1s ease, transform 1s ease;
  transition: box-shadow 0.3s ease, opacity 1s ease, transform 1s ease, -webkit-box-shadow 0.3s ease, -webkit-transform 1s ease;
  -webkit-box-shadow: 0px 0px #dcdcdc;
          box-shadow: 0px 0px #dcdcdc;
  cursor: pointer; }
  a.btn:hover,
  button.btn:hover {
    -webkit-box-shadow: 0px 10px #dcdcdc;
            box-shadow: 0px 10px #dcdcdc; }

.contact-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: start;
      align-items: flex-start;
  padding: 90px 0 110px; }
  @media (max-width: 1023px) {
    .contact-wrapper {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      padding: 20px 0 0; } }
  .contact-wrapper > div {
    width: 100%; }
  .contact-wrapper header {
    border-top: 1px solid #777777;
    border-bottom: 1px solid #777777;
    text-align: center;
    padding: 50px 0; }
    .contact-wrapper header .green {
      color: #1dbbb4;
      margin: 25px 0 5px; }
      .contact-wrapper header .green a {
        color: #1dbbb4; }
    .contact-wrapper header a {
      color: #31425a; }
  .contact-wrapper .underline {
    border-bottom: 1px solid #acb1b8; }
  .contact-wrapper h5 {
    margin-bottom: 25px; }

.google-map {
  height: 320px;
  width: 100%;
  margin: 50px 0; }

.person-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 110px;
  line-height: 24px; }
  .person-list > div {
    width: 45%;
    margin-bottom: 80px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
        align-items: flex-start; }
    @media (max-width: 1024px) and (min-width: 1024px) {
      .person-list > div {
        width: 90%; } }
    @media (max-width: 767px) {
      .person-list > div {
        width: 90%; } }
    .person-list > div a {
      color: #31425a; }
    .person-list > div p {
      font-size: 13px; }
      .person-list > div p:first-of-type {
        margin-bottom: 25px; }
    .person-list > div figure {
      min-width: 136px;
      margin-right: 25px; }
      .person-list > div figure img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover;
        border-radius: 10px; }

aside {
  background-color: #ffc321;
  padding: 70px 35px;
  -ms-flex: 1 0;
      flex: 1 0; }
  @media (min-width: 1024px) {
    aside {
      margin-left: 45px;
      -ms-flex: 1 0 425px;
          flex: 1 0 425px; } }
  aside p {
    line-height: 32px;
    display: -ms-flexbox;
    display: flex; }
    aside p span {
      font-size: 14px; }
      aside p span:first-of-type {
        width: 33%;
        font-family: "Roboto Bold", sans-serif; }

#footer {
  height: 750px;
  overflow: hidden;
  background-image: url("../images/parallax2.jpg");
  background-size: cover;
  color: white; }
  @media (max-width: 1023px) {
    #footer {
      height: 100%; } }
  @media (min-width: 768px) {
    #footer {
      background-attachment: fixed;
      background-position: center; } }
  @supports (background-blend-mode: overlay) {
    #footer {
      background-color: #31425a;
      background-blend-mode: overlay; } }
  @supports not (background-blend-mode: overlay) {
    #footer {
      position: relative;
      z-index: 1; }
      #footer:after {
        content: "";
        background-color: rgba(49, 66, 90, 0.39);
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1; } }
  #footer h4 {
    padding: 60px 0 40px; }
    @media (max-width: 1023px) {
      #footer h4 {
        text-align: center; } }
  #footer .footer-wrap {
    position: relative;
    height: 100%; }
  #footer .contact-wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between; }
    @media (max-width: 1023px) {
      #footer .contact-wrap {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -ms-flex-pack: center;
            justify-content: center; } }
  #footer .contact-data {
    text-align: center; }
    @media (max-width: 1023px) {
      #footer .contact-data {
        margin: 100px 0; } }
    #footer .contact-data p {
      font-size: 20px;
      line-height: 40px; }
      #footer .contact-data p:last-of-type {
        margin: 70px 0 25px; }
    #footer .contact-data h3 {
      margin-bottom: 70px; }
      #footer .contact-data h3 a {
        color: white; }
    #footer .contact-data .icon-phone {
      color: #1dbbb4;
      font-size: 30px;
      margin-right: 15px; }
  #footer form {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: start;
        align-items: flex-start;
    width: 100%; }
    @media (min-width: 768px) {
      #footer form {
        width: 520px; } }
    #footer form input,
    #footer form textarea {
      width: 100%;
      padding-left: 10px; }
    #footer form input {
      height: 50px;
      margin-bottom: 20px; }
    #footer form textarea {
      height: 215px;
      padding-top: 10px;
      margin-bottom: 0; }
    #footer form label {
      font-size: 11px;
      line-height: 18px;
      margin: 0 0 10px 5px;
      display: block; }
    #footer form span {
      font-size: 11px;
      margin: 0 10px; }
    #footer form .group,
    #footer form .checkbox {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      width: 100%; }
      #footer form .group > div,
      #footer form .checkbox > div {
        width: 50%; }
    @media (max-width: 359px) {
      #footer form .group {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
        #footer form .group > div {
          width: 100%; } }
    #footer form .btn {
      margin-left: auto;
      margin-top: 30px;
      font-family: "Roboto Bold", sans-serif;
      -webkit-box-shadow: 0px 0px rgba(220, 220, 220, 0.2);
              box-shadow: 0px 0px rgba(220, 220, 220, 0.2); }
      #footer form .btn:hover {
        -webkit-box-shadow: 0px 10px rgba(220, 220, 220, 0.2);
                box-shadow: 0px 10px rgba(220, 220, 220, 0.2); }
    #footer form .checkbox input[type="checkbox"] {
      background-color: white;
      -webkit-transition: background-color 0.3s ease;
      -o-transition: background-color 0.3s ease;
      transition: background-color 0.3s ease;
      height: 15px;
      min-width: 15px;
      max-width: 15px;
      margin: 0 10px 0 0;
      -webkit-appearance: none; }
    #footer form .checkbox input[type="checkbox"]:checked {
      background-color: #1cbbb4; }
    #footer form .checkbox label {
      margin-bottom: 0;
      margin-top: 30px;
      cursor: pointer; }
  #footer .subfooter {
    bottom: 0;
    position: absolute;
    width: 100%;
    left: 0;
    background-color: #2d3540;
    padding: 20px 50px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-align: center;
        align-items: center;
    font-size: 12px;
    line-height: 1.3;
    color: #99afae; }
    @media (max-width: 767px) {
      #footer .subfooter {
        padding: 20px 10px;
        font-size: 10px; } }
    #footer .subfooter a {
      color: #99afae; }
      #footer .subfooter a:hover {
        color: white; }



.asso {
  background-color: #f2f2f2;
  padding: 25px 0;
    
}
.asso .asso_div1{
    width: 100%;
}
.asso .asso_div2{
    width: 100%;
    text-align: center;
}



.asso > div {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-align: center;
        align-items: center; }

@media (max-width: 1023px) {
.asso > div {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -ms-flex-pack: center;
            justify-content: center; 
    text-align: center;} 
    
    .asso .asso_div2{
        margin-top:40px;
    }  
    
}

.slider_czysta{
    display: flex;
    justify-content: space-around !important;
    align-items: center;
}

@media (max-width: 767px) {
    .slider_czysta .asso_czysta_1{margin-bottom: 25px;}
}
    
.asso h2{
    font-family: "Roboto Bold", sans-serif;
    font-size: 35px !important;
    margin-bottom: 20px;
}
.asso .h2_mniejszy{
    font-size: 28px !important;
}
.asso span{
        font-family: "Roboto Light", sans-serif;
    font-size: 18px !important;
}
.asso img{
    max-width: 400px;
}
@media (max-width: 767px) {
    .asso h2{font-size: 25px !important;margin-bottom: 10px;}
    .asso .h2_mniejszy{font-size: 25px !important;}
    .asso span{font-size: 12px !important;}
    .asso img{max-width: 250px;}
    .asso .asso_div2{margin-top:25px;}  
    .slider_padding{padding-top:25px !important;}
}
.Rodo_link {
    color: #fff;
    font-weight: 600;
}

.icon-fb_n::before{
    content: "\e930";
}
.icon-linkedin_n::before{
    content: "\e929";
}
.slider_gorny .slide_gora {
    position: relative;
}
.slider_gorny {
    background-color: #f2f2f2;
}
.slider_gorny .slide_gora img {
    width: 100%;
    object-fit: cover;
}
.asso .asso_div3 img{
    max-width: 750px;
    
}
.asso .asso_div3{
    width: 100%;
    text-align: center;
}
.slider_padding{
    padding-top:50px;
    
}

/*-------------------------------------------------------------------
------------------------------News-----------------------------------
-------------------------------------------------------------------*/
.news_image{
  margin: 0px 30px 0px 30px;
}
.news_image img{
  max-width: 100%;
  margin-bottom: 35px;
}
.news_description{
  text-align: left;
  margin: 0px 30px 0px 30px;
}
.news_description p{
  line-height: 1.5em;
  text-align: justify;
  padding-bottom: 15px;
}
.news_description p:last-child{
  padding-bottom: 0px;
}


@media (min-width: 992px){
.post-text .video-container{
  display: flex;
  align-items: stretch;
}
}

.news-text-text{
  margin-right: 20px;
  padding: 0px !important;
}
.news-video {
  padding: 0px !important;
}
.news-video video{
  width: 100%;
}
/*-------------------------------------------------------------------
------------------------------Pop-up-----------------------------------
-------------------------------------------------------------------*/



.pop-up-disable.popup-content{
    opacity: 0;
    transition: all 0.2s ease-in-out; 
    transform: scale(0.8); 
  }
  .pop-up-disable.popup-overlay{
  opacity: 0;
  transition: all 0.3s ease-out;
  }
  .pop-up-enable.popup-content{
    opacity: 1;
    transform: scale(1);
  }
  .pop-up-enable.popup-overlay{
    opacity: 0.8;
  }

.modal {
  font-size: 14px;
  line-height: 22px;
  font-family: "Roboto", sans-serif;
  color: #31425a;
  animation: ease-in 3000ms;

}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 20px;
  text-align: center;
  padding: 15px;
  font-family: "Roboto Bold", sans-serif;
}
.modal > .content {
  text-align: center;
  padding: 20px 5px;
}
.modal > .footer {
  text-align: center;
  width: 100%;
  padding: 0px 5px 15px 5px;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

rodal,
.rodal-mask {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.rodal {
  position: fixed;
inset: 0px;
background: rgba(0, 0, 0, 0.5) none repeat scroll 0% 0%;
display: flex;
z-index: 999;
}

/* -- mask -- */
.rodal-mask {
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
}

/* -- dialog -- */
.rodal-dialog {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 101;
  padding: 15px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
background: rgb(255, 255, 255) none repeat scroll 0% 0%;
width: 50% !important;
height: auto !important;
margin: auto;
border: 1px solid rgb(187, 187, 187);
padding: 5px;
}

.rodal-dialog:focus {
  outline: none;
}

/* -- close button -- */
.rodal-close {
  position: absolute;
  cursor: pointer;
  top: 16px;
  right: 16px;
  width: 16px;
  height: 16px;
}

.rodal-close:before,
.rodal-close:after {
  position: absolute;
  content: '';
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #999;
  border-radius: 100%;
  -webkit-transition: background 0.2s;
  transition: background 0.2s;
}

.rodal-close:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.rodal-close:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.rodal-close:hover:before,
.rodal-close:hover:after {
  background: #333;
}

/* -- fade -- */
@-webkit-keyframes rodal-fade-enter {
  from {
    opacity: 0;
  }
}

@keyframes rodal-fade-enter {
  from {
    opacity: 0;
  }
}

.rodal-fade-enter {
  -webkit-animation: rodal-fade-enter both ease-in;
  animation: rodal-fade-enter both ease-in;
}

@-webkit-keyframes rodal-fade-leave {
  to {
    opacity: 0;
  }
}

@keyframes rodal-fade-leave {
  to {
    opacity: 0;
  }
}

.rodal-fade-leave {
  -webkit-animation: rodal-fade-leave both ease-out;
  animation: rodal-fade-leave both ease-out;
}

/* -- zoom -- */
@-webkit-keyframes rodal-zoom-enter {
  from {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes rodal-zoom-enter {
  from {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

.rodal-zoom-enter {
  -webkit-animation: rodal-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
  animation: rodal-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes rodal-zoom-leave {
  to {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes rodal-zoom-leave {
  to {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

.rodal-zoom-leave {
  -webkit-animation: rodal-zoom-leave both;
  animation: rodal-zoom-leave both;
}

/* -- slideDown -- */
@-webkit-keyframes rodal-slideDown-enter {
  from {
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
  }
}

@keyframes rodal-slideDown-enter {
  from {
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
  }
}

.rodal-slideDown-enter {
  -webkit-animation: rodal-slideDown-enter both cubic-bezier(0.4, 0, 0, 1.5);
  animation: rodal-slideDown-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes rodal-slideDown-leave {
  to {
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
  }
}

@keyframes rodal-slideDown-leave {
  to {
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
  }
}

.rodal-slideDown-leave {
  -webkit-animation: rodal-slideDown-leave both;
  animation: rodal-slideDown-leave both;
}

/* -- slideLeft -- */
@-webkit-keyframes rodal-slideLeft-enter {
  from {
    -webkit-transform: translate3d(-150px, 0, 0);
    transform: translate3d(-150px, 0, 0);
  }
}

@keyframes rodal-slideLeft-enter {
  from {
    -webkit-transform: translate3d(-150px, 0, 0);
    transform: translate3d(-150px, 0, 0);
  }
}

.rodal-slideLeft-enter {
  -webkit-animation: rodal-slideLeft-enter both cubic-bezier(0.4, 0, 0, 1.5);
  animation: rodal-slideLeft-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes rodal-slideLeft-leave {
  to {
    -webkit-transform: translate3d(-150px, 0, 0);
    transform: translate3d(-150px, 0, 0);
  }
}

@keyframes rodal-slideLeft-leave {
  to {
    -webkit-transform: translate3d(-150px, 0, 0);
    transform: translate3d(-150px, 0, 0);
  }
}

.rodal-slideLeft-leave {
  -webkit-animation: rodal-slideLeft-leave both;
  animation: rodal-slideLeft-leave both;
}

/* -- slideRight -- */
@-webkit-keyframes rodal-slideRight-enter {
  from {
    -webkit-transform: translate3d(150px, 0, 0);
    transform: translate3d(150px, 0, 0);
  }
}

@keyframes rodal-slideRight-enter {
  from {
    -webkit-transform: translate3d(150px, 0, 0);
    transform: translate3d(150px, 0, 0);
  }
}

.rodal-slideRight-enter {
  -webkit-animation: rodal-slideRight-enter both cubic-bezier(0.4, 0, 0, 1.5);
  animation: rodal-slideRight-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes rodal-slideRight-leave {
  to {
    -webkit-transform: translate3d(150px, 0, 0);
    transform: translate3d(150px, 0, 0);
  }
}

@keyframes rodal-slideRight-leave {
  to {
    -webkit-transform: translate3d(150px, 0, 0);
    transform: translate3d(150px, 0, 0);
  }
}

.rodal-slideRight-leave {
  -webkit-animation: rodal-slideRight-leave both;
  animation: rodal-slideRight-leave both;
}

/* -- slideUp -- */
@-webkit-keyframes rodal-slideUp-enter {
  from {
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0);
  }
}

@keyframes rodal-slideUp-enter {
  from {
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0);
  }
}

.rodal-slideUp-enter {
  -webkit-animation: rodal-slideUp-enter both cubic-bezier(0.4, 0, 0, 1.5);
  animation: rodal-slideUp-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes rodal-slideUp-leave {
  to {
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0);
  }
}

@keyframes rodal-slideUp-leave {
  to {
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0);
  }
}

.rodal-slideUp-leave {
  -webkit-animation: rodal-slideUp-leave both;
  animation: rodal-slideUp-leave both;
}

/* -- flip -- */
@-webkit-keyframes rodal-flip-enter {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 60deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 60deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

@keyframes rodal-flip-enter {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 60deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 60deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

.rodal-flip-enter {
  -webkit-animation: rodal-flip-enter both ease-in;
  animation: rodal-flip-enter both ease-in;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@-webkit-keyframes rodal-flip-leave {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 45deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 45deg);
  }
}

@keyframes rodal-flip-leave {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 45deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 45deg);
  }
}

.rodal-flip-leave {
  -webkit-animation: rodal-flip-leave both;
  animation: rodal-flip-leave both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

/* -- rotate -- */
@-webkit-keyframes rodal-rotate-enter {
  from {
    -webkit-transform: rotate3d(0, 0, 1, -180deg) scale3d(0.3, 0.3, 0.3);
    transform: rotate3d(0, 0, 1, -180deg) scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes rodal-rotate-enter {
  from {
    -webkit-transform: rotate3d(0, 0, 1, -180deg) scale3d(0.3, 0.3, 0.3);
    transform: rotate3d(0, 0, 1, -180deg) scale3d(0.3, 0.3, 0.3);
  }
}

.rodal-rotate-enter {
  -webkit-animation: rodal-rotate-enter both;
  animation: rodal-rotate-enter both;
  -webkit-transform-origin: center;
  transform-origin: center;
}

@-webkit-keyframes rodal-rotate-leave {
  to {
    -webkit-transform: rotate3d(0, 0, 1, 180deg) scale3d(0.3, 0.3, 0.3);
    transform: rotate3d(0, 0, 1, 180deg) scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes rodal-rotate-leave {
  to {
    -webkit-transform: rotate3d(0, 0, 1, 180deg) scale3d(0.3, 0.3, 0.3);
    transform: rotate3d(0, 0, 1, 180deg) scale3d(0.3, 0.3, 0.3);
  }
}

.rodal-rotate-leave {
  -webkit-animation: rodal-rotate-leave both;
  animation: rodal-rotate-leave both;
  -webkit-transform-origin: center;
  transform-origin: center;
}

/* -- door -- */
@-webkit-keyframes rodal-door-enter {
  from {
    -webkit-transform: scale3d(0, 1, 1);
    transform: scale3d(0, 1, 1);
  }
}

@keyframes rodal-door-enter {
  from {
    -webkit-transform: scale3d(0, 1, 1);
    transform: scale3d(0, 1, 1);
  }
}

.rodal-door-enter {
  -webkit-animation: rodal-door-enter both cubic-bezier(0.4, 0, 0, 1.5);
  animation: rodal-door-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes rodal-door-leave {
  60% {
    -webkit-transform: scale3d(0.01, 1, 1);
    transform: scale3d(0.01, 1, 1);
  }
  to {
    -webkit-transform: scale3d(0, 1, 0.1);
    transform: scale3d(0, 1, 0.1);
  }
}

@keyframes rodal-door-leave {
  60% {
    -webkit-transform: scale3d(0.01, 1, 1);
    transform: scale3d(0.01, 1, 1);
  }
  to {
    -webkit-transform: scale3d(0, 1, 0.1);
    transform: scale3d(0, 1, 0.1);
  }
}

.rodal-door-leave {
  -webkit-animation: rodal-door-leave both;
  animation: rodal-door-leave both;
}


.kontakt_mail{
  margin-top:20px;
}

.post{
  border-bottom: 1px solid #777777;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.post:last-child{
  border-bottom: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.post-row{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  padding: 15px 0px 15px 0px;
}
.post .post-row .post-text button{
  font-size: 1em;
  font-family: "Roboto Light", sans-serif;
  border: none;
  text-align: center;
  background-color: #1cbbb4;
  padding: 7px 15px;
  color: white;
  letter-spacing: 2px;
  transition: box-shadow 0.3s ease, opacity 1s ease, transform 1s ease;
  box-shadow: 0px 0px #dcdcdc;
  cursor: pointer;
}
.post .post-row .post-text button:hover{
  box-shadow: 0px 10px #dcdcdc;
}

.post .post-row .post-text a{
  color:  #1cbbb4 !important;
}
.post .post-row .post-text a:hover{
  color:  #1cbbb4 !important;
}
.post-col{
  padding: 15px;
  display: flex;
  flex-direction:column;
  flex-wrap: wrap;

}
.post-col img{
  width: 100%;
  cursor: pointer;
}

.post-col .post-text p{ 
line-height: 1.5em;
text-align: justify;
padding-bottom: 15px;
}

.post-col .post-text ul{ 
  line-height: 1.5em;
  text-align: justify;
  padding-bottom: 15px;
  list-style: circle;
  margin-left: 25px;
  }
  .post-col .post-text ol{ 
    line-height: 1.5em;
    text-align: justify;
    padding-bottom: 15px;
    list-style: decimal;
    margin-left: 25px;
    }
.post-col .post-text span{ 
  line-height: 1.5em;
  text-align: justify;
  padding-bottom: 15px;
  }
.post-col-1{
  order: 1;
}
.post-col-2{
  order: 2;
}
.post-header{
  background-color: #ffc321;
  font-family: "Roboto Medium", sans-serif;
  color: #31425a;
  text-transform: uppercase;
  font-size: 0.9rem;
  line-height: 0.9rem !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  min-height: 50px;
} 
.post-title{
  padding: 10px;
  text-align: left;
  line-height: 1.5;
}
.post-data{
  padding: 10px;
  text-align: right;
}
.post-article{
  display: block;
  color: white !important;
  width: 100%;
  margin-top:10px !important;
  font-size: 1rem !important;
  padding: 15px !important;
}

.post-text-read-more{
  text-align: right;
}
.post-text-read-more a{
  cursor: pointer;
  color: #1cbbb4 !important;
}

@media (max-width: 768px){
  .post-col-1{
    order: 2;
  }
  .post-col-2{
    order: 1;
  }
} 